<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="dataForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('dataForm')" />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="素材类型" prop="qtso_type">
            <el-select v-model="dataForm.qtso_type" clearable @change="qtsoTypeChange">
              <el-option v-for="item in qtsoTypeList" :key="item.value" :value="item.value" :label="item.label"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材名称" prop="qtso_name">
            <el-input v-model.trim="dataForm.qtso_name" maxlength="20" show-word-limit placeholder="请填写素材名称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材规格" prop="qtso_spec">
            <el-input v-model.trim="dataForm.qtso_spec" placeholder="请填写素材规格" maxlength="20" show-word-limit> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="是否计算损耗" prop="qtso_loss">
            <el-radio v-model="dataForm.qtso_loss" label="1">是</el-radio>
            <el-radio v-model="dataForm.qtso_loss" label="0">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="是否是日价" prop="qtso_daypr">
            <el-radio v-model="dataForm.qtso_daypr" label="1" :disabled="dataForm.qtso_type === '4'">是</el-radio>
            <el-radio v-model="dataForm.qtso_daypr" label="0" :disabled="dataForm.qtso_type === '4'">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="素材价格" prop="qtso_price">
            <el-input
              maxlength="9"
              v-model="dataForm.qtso_price"
              :disabled="!dataForm.qtso_daypr"
              placeholder="请填写素材价格"
              show-word-limit
              @input="
                dataForm.qtso_price =
                  dataForm.qtso_price
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,4})/g)[0] || ''
              "
              @change="dataForm.qtso_price = helper.calcPrice(dataForm.qtso_price, 4, 10000)"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="dataForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { QtsoAPI } from '@api/modules/qtso';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'custEditMain',
  components: {
    editHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        qtso_type: [{ required: true, trigger: 'blur', message: ' ' }],
        qtso_name: [{ required: true, trigger: 'blur', message: ' ' }],
        qtso_price: [{ required: true, trigger: 'blur', message: ' ' }],
        qtso_daypr: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        qtso_loss: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      dataForm: {
        qtso_type: null,
        qtso_name: null,
        qtso_spec: null,
        qtso_price: null,
        qtso_daypr: null,
        qtso_id: null,
        qtso_loss: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: true,
      qtsoTypeList: [
        { value: '1', label: '原材料' },
        { value: '2', label: '香精' },
        { value: '3', label: '夜光粉' },
        { value: '4', label: '颗粒' },
        { value: '5', label: '矿粉' },
        { value: '6', label: '色粉' },
        { value: '7', label: '成品损耗' },
        { value: '8', label: '辅助料材' },
        { value: '9', label: '水电费' },
        { value: '10', label: '运费' },
        { value: '11', label: '纸箱' },
        { value: '12', label: '塑料袋' },
        { value: '13', label: '人工工资' }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getQtsoInfo();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.dataForm.cust_id = props.form_id;
      this.dataForm.personal = this.$cookies.get('userInfo').personal;
      post(QtsoAPI.editQtso, this.dataForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('dataForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getQtsoInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.dataForm.qtso_id = props.form_id;
      console.log(props);
      get(QtsoAPI.getQtsoById, { qtso_id: this.dataForm.qtso_id })
        .then(res => {
          if (res.data.code === 0) {
            this.dataForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.dataForm.stff_name;
            this.stffForm.dept_name = this.dataForm.dept_name;
            this.stffForm.dept_team_name = this.dataForm.dept_team_name;
            this.stffForm.user_id = this.dataForm.user_id;
            this.stffForm.dept_id = this.dataForm.dept_id;
            this.stffForm.stff_id = this.dataForm.stff_id;
            this.dataForm.qtso_daypr += '';
            this.dataForm.qtso_type += '';
            this.dataForm.qtso_loss += '';
            this.dataForm.qtso_price = this.helper.haveFour(this.dataForm.qtso_price);
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    qtsoTypeChange(val) {
      if (val === '4') {
        this.dataForm.qtso_spec = '填写日产量后显示计算价格';
        this.dataForm.qtso_daypr = '1';
      } else {
        if (this.dataForm.qtso_spec === '填写日产量后显示计算价格') {
          this.dataForm.qtso_spec = '';
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
